<template>
  <label
    class="checkbox__label"
    :class="{ 'checkbox__label--checked': modelValue }"
  >
    <input
      type="checkbox"
      class="checkbox__input"
      id="check"
      :checked="modelValue"
      @change="toggle"
    />

    <span class="checkbox__box">
      <atomic-icon
        id="check"
        class="checkbox__icon"
      />
    </span>

    <div class="checkbox__text">
      <slot></slot>
    </div>
  </label>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'change']);

const toggle = (): void => {
  const newValue = !props.modelValue;

  emit('update:modelValue', newValue);
  emit('change', newValue);
};
</script>

<style src="~/assets/styles/components/form/input/checkbox.scss" lang="scss" />
